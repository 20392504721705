<template>
  <div class="page">
    <div class="heading">
      <v-toolbar :flat="flatapp" dense>
        <v-btn icon @click="menulink">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <v-breadcrumbs :items="crumbs">
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="primary" icon @click="refresh" v-on="on">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Refresh</span>
        </v-tooltip>
      </v-toolbar>
      <v-divider></v-divider>
    </div>

    <div class="body">
      <!-- <v-toolbar flat>
        <v-toolbar-title>My Schedule</v-toolbar-title>
      </v-toolbar>
      <v-divider></v-divider> -->
      <v-row>
        <v-col :md="calendarSize" cols="12">
          <v-card flat tile>
            <v-toolbar dense flat>
              <v-btn
                class="mr-4"
                color="grey darken-2"
                small
                text
                @click="setToday"
              >
                Today
              </v-btn>
              <v-btn color="grey darken-2" fab small text @click="prev">
                <v-icon small> mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn color="grey darken-2" fab small text @click="next">
                <v-icon small> mdi-chevron-right</v-icon>
              </v-btn>
              <v-toolbar-title v-if="$refs.calendar">
                {{ $refs.calendar.title }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-menu bottom right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="grey darken-2"
                    small
                    text
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span>{{ typeToLabel[type] }}</span>
                    <v-icon right> mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="type = 'day'">
                    <v-list-item-title>Day</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'week'">
                    <v-list-item-title>Week</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'month'">
                    <v-list-item-title>Month</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
            <div class="underline"></div>
            <v-card-text style="padding: 0px">
              <div>
                <v-sheet height="600">
                  <v-calendar
                    ref="calendar"
                    v-model="value"
                    :event-color="getEventColor"
                    :event-overlap-threshold="10"
                    :events="scheduleslist"
                    :start="isNow"
                    :type="type"
                    color="primary"
                    event-overlap-mode="stack"
                    @click:date="startSchedule"
                    @click:event="showEvent"
                  ></v-calendar>
                </v-sheet>
              </div>
            </v-card-text>
          </v-card>
          <ScheduleNew
            :newDialog="newDialog"
            :selectedDate="selectedDate"
            @closeNew="closeNew"
            @submitNew="submitNew"
          />
        </v-col>
        <v-col v-show="showDetail" cols="12" md="6">
          <v-toolbar dense flat>
            <v-toolbar-title>
              {{ detailSchedule | myDate }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="indigo"
                  text
                  x-small
                  v-on="on"
                  @click.stop="addDialog = true"
                >
                  new
                </v-btn>
              </template>
              <span>New Schedule on same date</span>
            </v-tooltip>
            <ScheduleAdd
              :addDialog="addDialog"
              :myavailableId="myAvailableId"
              :selectedDate="selectedDate"
              @closeAdd="closeAdd"
              @submitAdd="submitAdd"
            />

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="info"
                  text
                  x-small
                  v-on="on"
                  @click.stop="timeDialog = true"
                >
                  add
                </v-btn>
              </template>
              <span>Add more time to Schedule</span>
            </v-tooltip>
            <ScheduleTime
              :myId="myAvailableId"
              :selectedDate="selectedDate"
              :timeDialog="timeDialog"
              @closeTime="closeTime"
              @submitTime="submitTime"
            />

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="error"
                  text
                  x-small
                  v-on="on"
                  @click.stop="$set(deleteDialog, myAvailableId, true)"
                >
                  remove
                </v-btn>
              </template>
              <span>Delete whole Schedule</span>
            </v-tooltip>
            <ScheduleRemove
              v-if="deleteDialog[myAvailableId]"
              :deleteDialog="deleteDialog[myAvailableId]"
              :myId="myAvailableId"
              :selectedDate="selectedDate"
              @closeDelete="closeDelete"
              @submitDelete="submitDelete"
            />
          </v-toolbar>
          <v-divider style="margin: 0"></v-divider>
          <v-card flat tile>
            <v-card-actions style="padding: 7px 16px">
              <span class="schedule_caption_label"> Meeting Name </span>:
              <span class="schedule_caption">
                {{ scheduleTitles.meeting_name }}
              </span>
              <v-spacer></v-spacer>
              <span class="schedule_caption_label"> Meeting location </span>:
              <span class="schedule_caption">
                {{ scheduleTitles.meeting_location }}
              </span>
            </v-card-actions>
          </v-card>
          <v-card flat>
            <v-divider></v-divider>
            <v-card-text style="padding: 0px">
              <v-data-table
                :headers="headers"
                :items="scheduleDetails"
                :items-per-page="9"
                :sort-by="['id']"
                :sort-desc="[false]"
              >
                <template v-slot:item.accepted="{ item }">
                  {{ item.accepted ? "No" : "Yes" }}
                </template>

                <template v-slot:item.schedule_time_start="{ item }">
                  {{ item.schedule_time_start }}
                </template>

                <template v-slot:item.schedule_time_end="{ item }">
                  {{ item.schedule_time_end }}
                </template>

                <template v-slot:item.id="{ item }">
                  <v-btn
                    color="error"
                    icon
                    small
                    @click.stop="remove(item.id, item.accepted)"
                  >
                    <v-icon> mdi-delete-empty-outline</v-icon>
                  </v-btn>
                  <ScheduleDelete
                    v-if="removeDialog[item.id]"
                    :myId="item.id"
                    :removeDialog="removeDialog[item.id]"
                    @closeRemove="closeRemove"
                    @submitRemove="submitRemove"
                  />
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <v-snackbar
      v-model="snack.bar"
      :color="snack.color"
      :multi-line="snack.multiLine"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import WinScroll from "../../mixins/windowscroll";
import ScheduleNew from "../../components/appointments/schedules/newone.vue";
import ScheduleDelete from "../../components/appointments/schedules/remove.vue";
import ScheduleAdd from "../../components/appointments/schedules/add.vue";
import ScheduleTime from "../../components/appointments/schedules/addtimes.vue";
import ScheduleRemove from "../../components/appointments/schedules/deletion.vue";
import Utils from "../../mixins/utils";
import moment from "moment";

export default {
  components: {
    ScheduleNew,
    ScheduleDelete,
    ScheduleAdd,
    ScheduleTime,
    ScheduleRemove,
  },
  mixins: [Utils, WinScroll("position")],
  data() {
    return {
      isNow: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      addDialog: false,
      removeDialog: {},
      deleteDialog: {},
      timeDialog: false,
      type: "month",
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
      },
      value: "",
      showDetail: false,
      calendarSize: 12,
      newDialog: false,
      detailSchedule: null,
      selectedDate: null,
      myAvailableId: null,
      newSchedule: true,
      scheduleslist: [],
      scheduleDetails: [],
      scheduleTitles: {},
      headers: [
        { text: "start time", value: "schedule_time_start" },
        { text: "end time", value: "schedule_time_end" },
        { text: "available", value: "accepted", align: "center" },
        { text: "actions", value: "id" },
      ],
      crumbs: [
        {
          exact: true,
          link: true,
          text: "Dashboard",
          to: { name: "medics.dash" },
        },
        {
          exact: true,
          link: true,
          text: "Schedules",
          to: { name: "medics.schedule" },
        },
      ],
      snack: {
        color: "info",
        multiLine: true,
        bar: false,
        text: "Processing ... ",
      },
      mySelectedDate: null,
    };
  },
  mounted() {
    this.$refs.calendar.checkChange();
  },
  computed: {
    flatapp: function () {
      if (this.position[1] > 30) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    selectedDate: function (a) {
      if (a.length > 0) {
        this.newSchedule = false;
      } else {
        this.newSchedule = true;
      }
    },
  },
  created() {
    this.schedules();
  },
  methods: {
    refresh() {
      this.scheduleslist = [];
      this.schedules();
    },
    schedules() {
      // this.snack.bar = true;
      // let self = this;
      Restful.subscribers.available
        .available()
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            this.scheduleslist = response.data;
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    showEvent(e) {
      // console.log(e);
      this.selectedDate = e.eventParsed.start.date;
      this.myAvailableId = e.event.medics_availableid;
      console.log(this.myAvailableId);
      this.detailSchedule = this.selectedDate;
      let date = Date.parse(this.selectedDate);
      console.log(date);

      Restful.subscribers.available
        .scheduleDate(date)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            this.showDetail = true;
            this.calendarSize = 6;
            this.scheduleDetails = response.data.list;
            this.scheduleTitles = response.data.detail;
          } else {
            this.showDetail = false;
            this.calendarSize = 12;
            this.scheduleDetails = [];
            this.scheduleTitles = {};
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    startSchedule(e) {
      let ev = moment().subtract(1, "days").isAfter(e.date);
      console.log("ev:", ev);

      if (ev === true) {
        this.snack.bar = true;
        this.snack.color = "primary";
        this.snack.text = "Sorry, you cannot set a schedule for past date";
      } else {
        this.selectedDate = e.date;
        this.newDialog = true;
      }
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.value = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    remove(id, accepted) {
      console.log(id);
      console.log(accepted);
      if (accepted === false) {
        console.log(id);
        this.$set(this.removeDialog, id, true);
      } else {
        this.snack.bar = true;
        this.snack.text =
          "Sorry, the available is already booked and approved.";
      }
    },
    closeNew(p) {
      this.newDialog = p.state;
    },
    submitNew(p) {
      this.snack.bar = true;
      let self = this;
      Restful.subscribers.available
        .create(p.data)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            this.snack.color = "success";
            this.snack.text = response.data;
            this.schedules();
          }
        })
        .catch((error) => {
          console.log(error);
          self.snack.color = "error";
          self.snack.text = error.response;
        });

      this.newDialog = p.state;
    },
    menulink() {
      this.$emit("sidebar");
    },
    closeRemove(p) {
      this.$set(this.removeDialog, p.id, p.state);
    },
    submitRemove(p) {
      this.snack.bar = true;
      console.log(p);
      Restful.subscribers.available
        .removeTime(p.id)
        .then((response) => {
          console.log(response.data);
          this.snack.text = response.data;
          this.schedules();
          this.showDetail = false;
          this.calendarSize = 12;
        })
        .catch((error) => {
          console.log(error.response);
        });
      this.$set(this.removeDialog, p.id, p.state);
    },
    closeAdd(a) {
      this.addDialog = a.state;
    },
    submitAdd(a) {
      this.snack.bar = true;
      console.log(a);
      Restful.subscribers.available
        .create(a.data)
        .then((response) => {
          console.log(response.data);
          this.snack.text = response.data;
          this.schedules();
          this.showDetail = false;
          this.calendarSize = 12;
        })
        .catch((error) => {
          console.log(error.response);
        });
      this.addDialog = a.state;
    },
    closeTime(p) {
      // console.log(p);
      this.timeDialog = p.state;
    },
    submitTime(p) {
      this.snack.bar = true;
      Restful.subscribers.available
        .add(p.data)
        .then((response) => {
          console.log(response.data);
          this.snack.text = response.data;
          this.schedules();
          this.showDetail = false;
          this.calendarSize = 12;
        })
        .catch((error) => {
          console.log(error.response);
        });
      this.timeDialog = p.state;
    },
    closeDelete(p) {
      // console.log(p);
      this.$set(this.deleteDialog, p.id, p.state);
    },
    submitDelete(p) {
      this.snack.bar = true;
      Restful.subscribers.available
        .remove(p.id)
        .then((response) => {
          console.log(response.data);
          this.snack.text = response.data;
          this.schedules();
          this.showDetail = false;
          this.calendarSize = 12;
        })
        .catch((error) => {
          console.log(error.response);
        });
      this.$set(this.deleteDialog, p.id, p.state);
    },
  },
};
</script>
