<template>
  <v-dialog v-model="newDialog" max-width="700" persistent>
    <v-card>
      <v-card-title>new schedule</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6">
            <v-select
              v-model="create.my_meeting_typesid"
              :hint="feeHint"
              :items="meetingTyTpeList"
              item-text="meeting_type_name"
              item-value="id"
              label="basic consulting service type"
              persistent-hint
              return-object
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              v-model="create.provider_servicesid"
              :disabled="disableMeet"
              :items="proServicesList"
              item-text="name"
              item-value="id"
              label="Specific service"
            ></v-select>
          </v-col>
        </v-row>
        <v-text-field
          v-model="create.meetingLocation"
          label="meeting location"
        ></v-text-field>
        <v-row no-gutters>
          <v-col cols="12" sm="6">
            <v-menu
              ref="menu"
              v-model="startmenu"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="create.scheduleTimeStart"
              max-width="290px"
              min-width="290px"
              offset-y
              transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="create.scheduleTimeStart"
                  label="Starting Time"
                  prepend-icon="mdi-clock-time-four-outline"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="startmenu"
                v-model="create.scheduleTimeStart"
                full-width
                @click:minute="$refs.menu.save(create.scheduleTimeStart)"
              ></v-time-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6">
            <v-menu
              ref="menu1"
              v-model="endmenu"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="create.scheduleTimeEnd"
              max-width="290px"
              min-width="290px"
              offset-y
              transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="create.scheduleTimeEnd"
                  label="Ending Time"
                  prepend-icon="mdi-clock-time-four-outline"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="endmenu"
                v-model="create.scheduleTimeEnd"
                full-width
                @click:minute="$refs.menu1.save(create.scheduleTimeEnd)"
              ></v-time-picker>
            </v-menu>
          </v-col>
        </v-row>
        <div style="padding: 0 1px">
          <v-subheader style="padding: 0"
            >Select the duration you want to spend with a client</v-subheader
          >
          <v-slider
            v-model="create.maxSee"
            :hint="seeMe"
            :tick-labels="ticksLabels"
            append-icon="mdi-alarm"
            class="slideMe"
            max="7"
            min="0"
            persistent-hint
            ticks
          >
            <template v-slot:thumb-label="{ value }">
              {{ ticksLabels[value] }}
            </template>
          </v-slider>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="red" small text @click="closeDialog" v-on="on">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              :disabled="$v.create.$invalid"
              color="blue"
              small
              text
              @click="submit"
              v-on="on"
            >
              submit
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Restful from "@/services/RestFul";
import { validationMixin } from "vuelidate";
import { decimal, required } from "vuelidate/lib/validators";
import Utils from "../../../mixins/utils.js";

export default {
  mixins: [Utils, validationMixin],
  props: { newDialog: Boolean, selectedDate: { type: String, default: null } },
  data: () => ({
    value: 0,
    ticksLabels: [
      "15m",
      "30m",
      "45m",
      "1h",
      "2h",
      "3h",
      "4h",
      "5h",
      "6h",
      "7h",
    ],
    startmenu: false,
    endmenu: false,
    meetingTyTpeList: [],
    proServicesList: [],
    create: {
      maxSee: 0,
      scheduleDate: null,
      my_meeting_typesid: null,
      provider_servicesid: null,
      meetingLocation: null,
      scheduleTimeStart: null,
      scheduleTimeEnd: null,
    },
    feeHint: null,
    disableMeet: true,
    // seeMe: null,
  }),
  validations: {
    create: {
      maxSee: {
        required,
        decimal,
      },
      my_meeting_typesid: {
        required,
      },
      provider_servicesid: {
        required,
      },
      meetingLocation: {
        required,
      },
      scheduleTimeStart: {
        required,
      },
      scheduleTimeEnd: {
        required,
      },
    },
  },
  computed: {
    seeMe: function () {
      let m = this.create.maxSee;
      switch (m) {
        case 1:
          return "30 minutes";
        case 2:
          return "45 minutes";
        case 3:
          return "1 hour or 60 minutes";
        case 4:
          return "2 hours or 120 minutes";
        case 5:
          return "3 hours or 180 minutes";
        case 6:
          return "4 hours or 240 minutes";
        case 7:
          return "5 hours or 300 minutes";
        default:
          return "15 minutes";
      }
    },
  },
  watch: {
    "create.my_meeting_typesid": function (a) {
      console.log(a);
      this.feeHint = "Fee: " + this.currency(a.pricing);
      if (a.id > 0) {
        this.disableMeet = false;
        this.findByMeet(a.id);
      }
    },
  },
  created() {
    this.create.meetingLocation = localStorage.getItem("user.busines");
    this.meetTypes();
    // this.proServices();
  },
  methods: {
    closeDialog() {
      let payload = { state: false };
      this.$emit("closeNew", payload);
    },
    meetTypes() {
      let active = true;
      let d = { active };
      Restful.subscribers.myMeet
        .subscriberList(d)
        .then((response) => {
          console.log(response);
          this.meetingTyTpeList = response.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    findByMeet(id) {
      Restful.subscribers.proServices
        .findMeet(id)
        .then((response) => {
          console.log(response);
          this.proServicesList = response.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    maxSeeTime(a) {
      switch (a) {
        case 1:
          return 30;
        case 2:
          return 45;
        case 3:
          return 60;
        case 4:
          return 120;
        case 5:
          return 180;
        case 6:
          return 240;
        case 7:
          return 300;
        default:
          return 15;
      }
    },
    submit() {
      let my_meeting_typesid = this.create.my_meeting_typesid.id;
      let provider_servicesid = this.create.provider_servicesid;
      let meetingLocation = this.create.meetingLocation;
      let scheduleDate = this.selectedDate;
      let scheduleTimeStart = this.create.scheduleTimeStart;
      let scheduleTimeEnd = this.create.scheduleTimeEnd;
      let maxSee = parseInt(this.maxSeeTime(this.create.maxSee));
      let data = {
        maxSee,
        scheduleDate,
        my_meeting_typesid,
        provider_servicesid,
        meetingLocation,
        scheduleTimeStart,
        scheduleTimeEnd,
      };
      console.log("data:", data);
      let payload = { state: false, data: data };
      this.$emit("submitNew", payload);
    },
  },
};
</script>
