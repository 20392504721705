<template>
  <v-dialog v-model="timeDialog" max-width="700" persistent>
    <v-card>
      <v-card-title>new schedule on {{ selectedDate | myDate }}</v-card-title>
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12" sm="6">
            <v-menu
                ref="menu"
                v-model="startmenu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="create.starting"
                max-width="290px"
                min-width="290px"
                offset-y
                transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="create.starting"
                    label="Starting Time"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                  v-if="startmenu"
                  v-model="create.starting"
                  full-width
                  @click:minute="$refs.menu.save(create.starting)"
              ></v-time-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6">
            <v-menu
                ref="menu1"
                v-model="endmenu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="create.ending"
                max-width="290px"
                min-width="290px"
                offset-y
                transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="create.ending"
                    label="Ending Time"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                  v-if="endmenu"
                  v-model="create.ending"
                  full-width
                  @click:minute="$refs.menu1.save(create.ending)"
              ></v-time-picker>
            </v-menu>
          </v-col>
        </v-row>

        <div style="padding: 0 1px">
          <v-subheader style="padding: 0">Select the duration you want to spend with a client</v-subheader>
          <v-slider v-model="create.maxSee"
                    :hint="seeMe"
                    :tick-labels="ticksLabels"
                    append-icon="mdi-alarm" class="slideMe"
                    max="7"
                    min="0"
                    persistent-hint ticks
          >
            <template v-slot:thumb-label="{ value }">
              {{ ticksLabels[value] }}
            </template>
          </v-slider>
        </div>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="red" small text @click="closeDialog" v-on="on">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
                :disabled="$v.create.$invalid"
                color="blue"
                small
                text
                @click="submit"
                v-on="on"
            >
              submit
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {validationMixin} from "vuelidate";
import {decimal, required} from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  props: {
    timeDialog: Boolean,
    myId: {type: Number},
    selectedDate: {type: String, default: null},
  },
  data: () => ({
    ticksLabels: ["15m", "30m", "45m", "1h", "2h", "3h", "4h", "5h", "6h", "7h"],
    startmenu: false,
    endmenu: false,
    create: {
      starting: null,
      ending: null,
      maxSee: 0,
    },
  }),
  validations: {
    create: {
      starting: {
        required,
      },
      ending: {
        required,
      },
      maxSee: {
        required,
        decimal,
      },
    },
  },
  computed: {
    seeMe: function () {
      let m = this.create.maxSee;
      switch (m) {
        case 1:
          return '30 minutes';
        case 2:
          return '45 minutes';
        case 3:
          return '1 hour or 60 minutes';
        case 4:
          return '2 hours or 120 minutes';
        case 5:
          return '3 hours or 180 minutes';
        case 6:
          return '4 hours or 240 minutes';
        case 7:
          return '5 hours or 300 minutes';
        default:
          return '15 minutes';
      }
    }
  },
  created() {
  },
  methods: {
    maxSeeTime(a) {
      switch (a) {
        case 1:
          return 30;
        case 2:
          return 45;
        case 3:
          return 60;
        case 4:
          return 120;
        case 5:
          return 180;
        case 6:
          return 240;
        case 7:
          return 300;
        default:
          return 15;
      }
    },
    closeDialog() {
      let payload = {state: false};
      this.$emit("closeTime", payload);
    },
    submit() {
      let id = this.myId;
      let scheduleDate = this.selectedDate;
      let scheduleTimeStart = this.create.starting;
      let scheduleTimeEnd = this.create.ending;
      let maxSee = parseInt(this.maxSeeTime(this.create.maxSee));
      let data = {
        id,
        scheduleDate,
        scheduleTimeStart,
        scheduleTimeEnd,
        maxSee,
      };
      // console.log(data);
      let payload = {state: false, data: data};
      this.$emit("submitTime", payload);
    },
  },
};
</script>
