<template>
  <div>
    <v-dialog v-model="deleteDialog" max-width="400" persistent>
      <v-card>
        <v-card-title>remove schedule</v-card-title>
        <v-card-text>
          Are you sure you want to permanently remove this schedule for
          <b>{{ selectedDate | myDate }}</b> ?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn color="red" small text @click="closeDialog" v-on="on">
                cancel
              </v-btn>
            </template>
            <span>cancel</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn color="blue" small text @click="submit" v-on="on">
                submit
              </v-btn>
            </template>
            <span>Submit</span>
          </v-tooltip>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    deleteDialog: Boolean,
    myId: {type: Number, default: 0},
    selectedDate: {type: String, default: null},
  },
  data: () => ({}),
  created() {
  },
  methods: {
    closeDialog() {
      let payload = {id: this.myId, state: false};
      this.$emit("closeDelete", payload);
    },
    submit() {
      let payload = {id: this.myId, state: false};
      this.$emit("submitDelete", payload);
    },
  },
};
</script>
